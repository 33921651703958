<template>
  <el-form
    class="up-add-user-form"
    :model="form"
    :rules="rules"
    ref="form"
    inline
  >
    <el-form-item label="Имя" prop="name">
      <el-input v-model="form.name" placeholder="Имя" />
    </el-form-item>
    <el-form-item label="Телефон" prop="phone">
      <el-input v-model="phoneNumber" placeholder="Телефон" />
    </el-form-item>
    <el-form-item label="Email">
      <el-input v-model="form.email" placeholder="Email" />
    </el-form-item>
    <el-form-item label="Пароль">
      <el-input v-model="form.password" placeholder="Пароль" />
    </el-form-item>
    <el-form-item label="Роли пользователя" prop="roles">
      <el-select
        v-model="form.roles"
        multiple
        default-first-option
        placeholder="Выберите роли пользователя"
      >
        <el-option
          v-for="r in roles"
          :key="r.key"
          :label="r.value"
          :value="r.key"
        />
      </el-select>
    </el-form-item>
    <el-button
      v-loading="loading"
      type="primary"
      icon="el-icon-user"
      @click="createUser"
    >
      Создать
    </el-button>
  </el-form>
</template>

<script>
import validatableFormMixin from "@/mixins/validatableForm.mixin";
import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";

import userRoles from "@/enums/userRoles";

import Str from "@/helpers/Str";

const requiredValidation = [
  { required: true, message: "Необходимо заполнить", trigger: "blur" },
];

export default {
  name: "AddNewUser",
  mixins: [validatableFormMixin, showErrorMessageMixin],
  data: () => ({
    form: {
      name: "",
      email: "",
      phone: "",
      password: "",
      roles: [],
    },
    rules: {
      name: requiredValidation,
      phone: requiredValidation,
      roles: requiredValidation,
    },
    loading: false,
    roles: [...userRoles],
  }),
  computed: {
    phoneNumber: {
      get() {
        return this.form.phone;
      },
      set(val) {
        this.form.phone = Str.phoneFormatted(val);
      },
    },
  },
  methods: {
    clearForm() {
      this.form = {
        name: "",
        email: "",
        phone: "",
        password: "",
        roles: [],
      };
    },
    async createUser() {
      if (!(await this.isValid()) || this.loading) return;

      this.loading = true;

      try {
        const { data: res } = await this.$axios.post("/admin/user", this.form);

        this.$emit("add-user", res);

        this.clearForm();

        this.$message({
          message: "Поздравляем! Пользователь успешно создан.",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/admin/user/add-new-user.scss";
</style>