<template>
  <div class="up-simple-card">
    <div class="up-simple-card__header">
      <span>№{{ userInfo.id.slice(0, 3) }}...</span>
      <el-dropdown trigger="click">
        <i class="el-icon-more" />
        <el-dropdown-menu slot="dropdown">
          <el-button
            type="text"
            icon="el-icon-lock"
            @click="$emit('block', userInfo)"
          >
            <template v-if="userInfo.blocked">разблокировать</template>
            <template v-else>заблокировать</template>
          </el-button>
          <el-button
            type="text"
            icon="el-icon-delete"
            @click="$emit('remove', userInfo.id)"
          >
            удалить
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            @click="$emit('edit', userInfo.id)"
          >
            редактировать
          </el-button>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <p><span>Имя:</span> {{ userInfo.name }}</p>
    <p><span>Email:</span> {{ userInfo.email }}</p>
    <p><span>телефон:</span> {{ userInfo.phone }}</p>
    <p>
      <span>Роли:</span>
      <template v-for="r in userInfo.roles">
        {{ userRoleTranslated(r) }}
      </template>
    </p>
    <p>
      <span>Статус:</span> {{ userInfo.blocked ? "Заблокирован" : "Активен" }}
    </p>
  </div>
</template>

<script>
export default {
  name: "UserCard",
  props: {
    userInfo: Object,
    userRoleTranslated: Function,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/common/simple-card.scss";
</style>