<template>
  <el-dialog
    center
    modal-centered
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    custom-class="up-edit-modal"
    v-bind="$attrs"
    v-on="$listeners"
    @open="setDefaultData"
  >
    <el-form>
      <el-form-item label="Имя">
        <el-input v-model="form.name" placeholder="Имя" />
      </el-form-item>
      <el-form-item label="Email">
        <el-input v-model="form.email" placeholder="Email" />
      </el-form-item>
      <el-form-item label="Телефон">
        <el-input v-model="form.phone" placeholder="Телефон" />
      </el-form-item>
      <el-form-item label="Пароль">
        <el-input v-model="form.password" placeholder="Пароль" show-password />
      </el-form-item>
      <el-form-item label="Роли">
        <el-select
          v-model="form.roles"
          multiple
          default-first-option
          placeholder="Выберите роли пользователя"
          style="width: 100%"
        >
          <el-option
            v-for="r in roles"
            :key="r.key"
            :label="r.value"
            :value="r.key"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div class="up-edit-modal__buttons">
      <el-button type="danger" @click="$emit('close')">Отмена</el-button>
      <el-button type="primary" @click="editHandler">Сохранить</el-button>
    </div>
  </el-dialog>
</template>

<script>
import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";

import userRoles from "@/enums/userRoles";

export default {
  name: "EditUserModal",
  mixins: [showErrorMessageMixin],
  props: {
    userInfo: Object,
  },
  data: () => ({
    form: {
      roles: [],
      name: "",
      phone: "",
      email: "",
      password: "",
    },
    roles: [...userRoles],
  }),
  methods: {
    setDefaultData() {
      this.form.roles = this.userInfo.roles.map((r) => r.name);
      this.form.name = this.userInfo.name;
      this.form.phone = this.userInfo.phone;
      this.form.email = this.userInfo.email;
      this.form.password = "";
    },
    async editHandler() {
      try {
        await this.$axios.put("/admin/user", {
          id: this.userInfo.id,
          ...this.form,
        });

        this.$emit("update-user-info");

        this.$message({
          message: "Поздравляем! Данные пользователя обновлены",
          type: "success",
        });
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/admin/user/edit-user-modal.scss";
</style>