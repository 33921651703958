<template>
  <div class="up-users-wrapper">
    <header class="up-main-header">
      <h1 class="up-main-title">Пользователи и роли</h1>
      <el-input
        v-model="searchValue"
        prefix-icon="el-icon-search"
        placeholder="Поиск по пользователям"
        @change="filterHandler"
      />
    </header>
    <template v-if="totalSize > 0">
      <AppSort
        :sort-params="sortParams"
        :sort-by="sortBy"
        :sort-order="sortOrder"
        @sort="sortHandler($event, 'fetchUsers')"
      />
      <AppBlockAsTable class="up-users-wrapper__users up-users">
        <template slot="header">
          <!--          <p
            class="up-users__number"
            :class="activeSortClass('number')"
            @click="sortHandler('number', 'fetchUsers')"
          >
            Номер <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>
-->
          <p
            class="up-users__name"
            :class="activeSortClass('name')"
            @click="sortHandler('name', 'fetchUsers')"
          >
            Имя <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>
          <p
            class="up-users__email"
            :class="activeSortClass('email')"
            @click="sortHandler('email', 'fetchUsers')"
          >
            Email <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>
          <p
            class="up-users__phone"
            :class="activeSortClass('phone')"
            @click="sortHandler('phone', 'fetchUsers')"
          >
            Телефон <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>
          <p
            class="up-users__roles"
            :class="activeSortClass('roles')"
            @click="sortHandler('roles', 'fetchUsers')"
          >
            Роли <i class="el-icon-top" /> <i class="el-icon-bottom" />
          </p>
          <p class="up-users__status">Статус</p>
          <p class="up-users__actions"></p>
        </template>
        <div v-for="u in users" :key="u.id" class="up-table__item">
          <!--          <p class="up-users__number">
            <span class="is-pk-hidden">Номер:</span>{{ u.id.slice(0, 3) }}...
          </p>
-->
          <p class="up-users__name">
            <span class="is-pk-hidden">Имя:</span>{{ u.name }}
          </p>
          <p class="up-users__email">
            <span class="is-pk-hidden">Email:</span>{{ u.email }}
          </p>
          <p class="up-users__phone">
            <span class="is-pk-hidden">Телефон:</span>{{ u.phone }}
          </p>
          <p class="up-users__roles">
            <span class="is-pk-hidden">Роли:</span>
            <template v-for="r in u.roles">
              {{ userRoleTranslated(r) }}
            </template>
          </p>
          <p class="up-users__status">
            <span class="is-pk-hidden">Статус:</span>
            <el-tag>{{ u.blocked ? "Заблокирован" : "Активен" }}</el-tag>
          </p>
          <p class="up-users__actions">
            <el-dropdown trigger="click">
              <i class="el-icon-more" />
              <el-dropdown-menu slot="dropdown">
                <el-button
                  type="text"
                  icon="el-icon-lock"
                  @click="openBlockModal(u)"
                >
                  <template v-if="u.blocked">разблокировать</template>
                  <template v-else>заблокировать</template>
                </el-button>
                <el-button
                  type="text"
                  icon="el-icon-delete"
                  @click="openRemoveModal(u.id)"
                >
                  удалить
                </el-button>
                <el-button
                  type="text"
                  icon="el-icon-edit"
                  @click="openEditModal(u.id)"
                >
                  редактировать
                </el-button>
              </el-dropdown-menu>
            </el-dropdown>
          </p>
        </div>
      </AppBlockAsTable>
      <div class="up-users-wrapper__mob">
        <UserCard
          v-for="u in users"
          :key="`user-${u.id}`"
          :user-info="u"
          :user-role-translated="userRoleTranslated"
          @block="openBlockModal"
          @remove="openRemoveModal"
          @edit="openEditModal"
        />
      </div>
      <el-pagination
        v-if="totalSize > perPage"
        background
        layout="prev, pager, next"
        :total="totalSize"
        :current-page.sync="currentPage"
        :page-size="perPage"
        @current-change="fetchUsers"
      />
    </template>
    <p v-else-if="!loading" class="up-empty-list-text">Список пуст</p>
    <AddNewUser @add-user="addNewUser" />
    <EditUserModal
      :visible.sync="editUserModalVisible"
      :user-info="editableUserInfo"
      :title="`Редактирование пользователя: ${editableUserInfo.name}`"
      @update-user-info="updateUserInfo"
      @close="editUserModalVisible = false"
    />
    <AppSimpleModal
      :visible.sync="blockModalVisible"
      title="Изменение статуса пользователя"
      :action-text="
        blockedUserInfo.blocked ? 'Разблокировать' : 'Заблокировать'
      "
      @action="blockUserHandler"
      @close="blockModalVisible = false"
    >
      Вы хотите
      <template v-if="blockedUserInfo.blocked">разблокировать</template>
      <template v-else>заблокировать</template>
      пользователя. Выберите действие.
    </AppSimpleModal>
    <AppSimpleModal
      :visible.sync="removeModalVisible"
      title="Удаление пользователя"
      @action="removeUserHandler"
      @close="removeModalVisible = false"
    >
      Вы хотите удалить пользователя. Выберите действие.
    </AppSimpleModal>
  </div>
</template>

<script>
import AppBlockAsTable from "@/components/AppBlockAsTable";
import AppSort from "@/components/AppSort";
import EditUserModal from "@/components/admin/users/EditUserModal";
import AddNewUser from "@/components/admin/users/AddNewUser";
import AppSimpleModal from "@/components/AppSimpleModal";
import UserCard from "@/components/admin/users/UserCard";

import showErrorMessageMixin from "@/mixins/showErrorMessage.mixin";
import sortMixin from "@/mixins/sort.mixin";

import userRoles from "@/enums/userRoles";

import { mapActions } from "vuex";

export default {
  name: "Users",
  mixins: [showErrorMessageMixin, sortMixin],
  components: {
    AppBlockAsTable,
    EditUserModal,
    AddNewUser,
    AppSimpleModal,
    UserCard,
    AppSort,
  },
  data: () => ({
    roles: [...userRoles],
    users: [],
    loading: true,
    searchValue: "",
    editUserModalVisible: false,
    removeModalVisible: false,
    blockModalVisible: false,
    editableUserInfo: {},
    idOfRemovingUser: null,
    blockedUserInfo: {},
    perPage: 10,
    currentPage: 1,
    totalSize: 0,
    sortParams: [
      {
        text: "Номеру",
        key: "number",
      },
      {
        text: "Имени",
        key: "name",
      },
      {
        text: "Email",
        key: "email",
      },
      {
        text: "Телефону",
        key: "phone",
      },
      {
        text: "Ролям",
        key: "roles",
      },
    ],
  }),
  methods: {
    ...mapActions(["setNavigationText"]),
    async fetchUsers() {
      this.$nprogress.start();

      try {
        const { data: users } = await this.$axios.get("/admin/users", {
          params: {
            pageSize: this.perPage,
            pageNumber: this.currentPage - 1,
            sortBy: this.sortBy,
            sortOrder: this.sortOrder,
            q: this.searchValue,
          },
        });

        this.users = users.items;
        this.totalSize = users.totalSize;
      } catch (e) {
        this.showErrorMessage(e);
      } finally {
        this.loading = false;
        this.$nprogress.done();
      }
    },
    filterHandler() {
      this.currentPage = 1;
      this.fetchUsers();
    },
    userRoleTranslated(r) {
      const userRole = this.roles.find((role) => role.key === r.name);

      if (userRole) return userRole.value;

      return this.roles.find((role) => role.key === r).value;
    },
    addNewUser(userData) {
      this.users.push(userData);
    },
    openRemoveModal(userId) {
      this.removeModalVisible = true;
      this.idOfRemovingUser = userId;
    },
    async removeUserHandler() {
      try {
        await this.$axios.delete(`/admin/user/${this.idOfRemovingUser}`);

        this.users = this.users.filter((u) => u.id !== this.idOfRemovingUser);

        this.$message({
          message: "Поздравляем! Пользователь успешно удален",
          type: "success",
        });

        this.removeModalVisible = false;
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    openBlockModal(userInfo) {
      this.blockedUserInfo = userInfo;
      this.blockModalVisible = true;
    },
    async blockUserHandler() {
      const method = this.blockedUserInfo.blocked ? "unlock" : "lock";
      const userInfo = this.users.find((u) => u.id === this.blockedUserInfo.id);
      const text = userInfo.blocked ? "разблокирован" : "заблокирован";

      try {
        await this.$axios.patch(
          `/admin/user/${this.blockedUserInfo.id}/${method}`
        );

        userInfo.blocked = !userInfo.blocked;

        this.$message({
          message: `Поздравляем! Пользователь успешно ${text}`,
          type: "success",
        });

        this.blockModalVisible = false;
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
    openEditModal(userId) {
      this.editableUserInfo = this.users.find((u) => u.id === userId);
      this.editUserModalVisible = true;
    },
    updateUserInfo() {
      this.fetchUsers();
      this.editUserModalVisible = false;
    },
  },
  created() {
    this.setNavigationText("Страница пользователей и ролей");

    this.fetchUsers();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/admin/users.scss";
</style>